
/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-spacer'

// Properties
const props = {
  mjml: Object
}

// Computed Methods
const computed = {
  height: {
    // getter
    get () {
      return parseInt(this.mjml.attributes.height, 10)
    },
    // setter
    set (newValue) {
      this.mjml.attributes.height = `${newValue}px`
    }
  }
}

// Vue component syntax
export default {
  name,
  props,
  computed
}
